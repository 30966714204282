import BottomButton from "@/components/BottomButton";
import trafficInfectious from "@/components/documents/trafficInfectious";
import trafficCritically from "@/components/documents/trafficCritically";
import trafficDisclaimer from "@/components/documents/trafficDisclaimer";
import trafficNotice from "@/components/documents/trafficNotice";
import trafficDisclosure from "@/components/documents/trafficDisclosure";
import trafficPolicy from "@/components/documents/trafficPolicy";

import { registerComponent } from "@/utils";
import { Tab, Tabs } from "vant";

export const components = registerComponent({
  Tab,
  Tabs,
  BottomButton,
  trafficInfectious,
  trafficCritically,
  trafficDisclaimer,
  trafficNotice,
  trafficDisclosure,
  trafficPolicy,
});

export default { components };
